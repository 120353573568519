<script>
import { mapStores } from 'pinia'
import { useAppStore } from '@/stores'

export default {
  name: 'SignInApple',

  components: {},

  props: {
    something: {
      type: Object,
      default: null,
    },
  },

  methods: {},

  computed: {
    ...mapStores(useAppStore),

    hasApple() {
      // return import.meta.env.VITE_APPLE_CLIENT_ID !== 'none'
      return this.app.hasSigninWithApple && window.AppleID !== undefined
    },
  },

  data: () => ({}),

  async mounted() {
    window.AppleID.auth.init({
      clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
      scope: 'name email',
      redirectURI: '[REDIRECT_URI]',
      state: '[STATE]',
      // nonce: '[NONCE]',
      usePopup: true,
    })
  },
}
</script>

<template>
  <div
    v-if="hasApple"
    class="is-flex"
    style="margin-bottom: 1rem; justify-content: center">
    <div
      id="appleid-signin"
      data-mode="center-align"
      data-type="sign-in"
      data-color="white"
      data-border="true"
      data-border-radius="15"
      data-width="267"
      data-height="48"></div>
  </div>
</template>

<style scoped lang="scss">
.modal {
  gap: 1rem;
  justify-content: center;
}
</style>
