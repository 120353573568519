<script>
import utils from '@/utils.js'

export default {
  name: 'PrettyDate',

  components: {},

  props: {
    date: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'light',
    },
    tooltipPosition: {
      type: String,
      default: 'right',
    },
  },

  methods: {},

  computed: {
    prettyDate() {
      return utils.prettyDate(this.date)
    },

    prettyDateTime() {
      return utils.prettyDateTime(this.date)
    },
  },

  data: () => ({}),

  async mounted() {},
}
</script>

<template>
  <o-tooltip
    class="pretty-date"
    :variant="type"
    :label="prettyDateTime"
    :position="tooltipPosition">
    {{ prettyDate }}
  </o-tooltip>
</template>

<style lang="scss" scoped>
p.pretty-date {
}
</style>
